import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import logo from "../images/Cross-church-web.png"

const NavLink = styled(Link)`
  color: #f7fafc;
  font-size: 1.2rem;
  line-height: 2rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 400;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
`

const ExternalLink = styled.a`
  color: #f7fafc;
  font-size: 1.2rem;
  line-height: 2rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 400;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
`

const FooterHeader = styled.a`
  font-weight: 600;
  text-decoration: none;
  font-family: "Montserrat";
  color: #e2e8f0;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
`

const Footer = () => (
  <header
    css={css`
      background: #f07525;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem calc((100vw - 1050px) / 2);
      height: 16rem;
      align-items: center;
      @media (max-width: 1050px) {
        padding: 2rem;
      }
      @media (max-width: 640px) {
        flex-direction: column;
        height: 20rem;
      }
    `}
  >
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
    >
      <img
        css={css`
          height: 3rem;
        `}
        src={logo}
        alt=""
      />
    </div>
    <nav
      css={css`
        display: flex;
        flex-direction: row;
      `}
    >
      <ul
        css={css`
          @media (max-width: 640px) {
            padding-inline-start: 0px;
          }
        `}
      >
        <li
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <FooterHeader>ABOUT US</FooterHeader>
          <NavLink to="/whoweare">Who We Are</NavLink>
          <NavLink to="/mission-and-vision">Our Mission and Vision</NavLink>
          <NavLink to="/beliefs">Statement of Faith</NavLink>
          <ExternalLink href="https://cross.academy">
            Cross Christian Academy
          </ExternalLink>
        </li>
      </ul>
      <ul>
        <li
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: 2rem;
          `}
        >
          <FooterHeader>NEW TO CROSS?</FooterHeader>
          <NavLink to="/whattoexpect">What to Expect</NavLink>
          <NavLink to="/">Our Locations</NavLink>
          <NavLink to="/visit">Schedule a Visit</NavLink>
        </li>
      </ul>
    </nav>
  </header>
)

export default Footer
