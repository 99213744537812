import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import logo from "../images/Cross-church-web.png"
import BurgerMenuLanding from "../components/burgerMenuLanding"
import PopupLive from "../components/popupLive"
import GoodFridayLive from "../components/popupGoodFriday"
import EasterLive from "../components/popupEaster"
import PopupPodcast from "../components/popupPodcast"
import BannerTemplate from "./bannerTemplate"
import EasterBanner from "./EasterBanner"

const GiveLink = styled.a`
  color: #f7fafc;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1;
  margin: 0.25rem;
  padding: 1rem;
  text-decoration: none;
  align-items: center;
  text-transform: uppercase;
  display: flex;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #615b5b;
    border-radius: 4px;
  }
`

const DropDownContent = styled.div`
  display: none;
  transition: 0.25s;
  position: absolute;
  background-color: #312e2e;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  &:hover {
    display: block;
    transition: 0.25s;
  }
`

const HoverLink = styled(Link)`
  color: #f7fafc;
  font-size: 0.8rem;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1;
  align-items: center;
  display: flex;
  padding: 1rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #615b5b;
    border-radius: 4px;
  }
  &:hover + ${DropDownContent} {
    display: block;
    transition: 0.25s;
  }
  &.current-page {
    background: #615b5b;
    border-radius: 4px;
  }
`

const HoverNonLink = styled.a`
  color: #f7fafc;
  font-size: 0.8rem;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1;
  align-items: center;
  display: flex;
  padding: 1rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  cursor: pointer;
  &:hover {
    transition: 0.25s;
    background: #615b5b;
    border-radius: 4px;
  }
  &:hover + ${DropDownContent} {
    display: block;
    transition: 0.25s;
  }
  &.current-page {
    background: #615b5b;
    border-radius: 4px;
  }
`

const NavLink = styled(Link)`
  color: #f7fafc;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  padding: 1rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #615b5b;
    border-radius: 4px;
  }
  &.current-page {
    background: #615b5b;
    border-radius: 4px;
  }
`

const CovidLink = styled(Link)`
  color: #f7fafc;
  font-size: 0.8rem;
  background: #615b5b;
  font-weight: normal;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  border-radius: 4px;
  display: flex;
  text-transform: uppercase;
  padding: 1rem;
  text-decoration: none;
  font-family: "Montserrat";
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #f4f4f4;
    color: #333333;
    border-radius: 4px;
  }
  &.current-page {
    background: #f4f4f4;
    border-radius: 4px;
  }
`

const ViewMore = styled(Link)`
  color: #f7fafc;
  background: #22543d;
  font-size: 0.8rem;
  line-height: 1;
  padding-right: 1rem;
  align-items: center;
  margin-left: 2rem;
  display: flex;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0.6rem;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    transition: 0.25s;
    background: #615b5b;
    border-radius: 4px;
  }
  @media (max-width: 640px) {
    text-align: center;
    margin-right: 0.5rem;
  }
  @media (max-width: 340px) {
    font-size: 0.8rem;
  }
`

const HiddenDiv = styled.div`
  @media (max-width: 640px) {
    display: none;
  }
`

const LogoLink = styled(Link)`
  height: 2rem;
`

const Header = () => (
  <>
    <header
      css={css`
        background: #312e2e;
        border-bottom: 1px solid #000;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem calc((100vw - 1050px) / 2);
        height: 4rem;
        align-items: center;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        filter: alpha(opacity=90);
        -moz-opacity: 0.9;
        -khtml-opacity: 0.9;
        opacity: 0.9;
        position: fixed;
        right: 0;
        left: 0;
        z-index: 999;
      `}
    >
      <LogoLink to="/">
        <img
          css={css`
            height: 2rem;
            @media (max-width: 1050px) {
              margin-left: 1.5rem;
            }
          `}
          src={logo}
          alt=""
        />
      </LogoLink>
      <nav
        css={css`
          margin-top: 0;
          display: flex;
          align-items: center;
        `}
      >
        <BurgerMenuLanding />
        <HiddenDiv>
          <HoverNonLink>LOCATIONS</HoverNonLink>
          <DropDownContent>
            <NavLink to="/surprise">Surprise</NavLink>
            <NavLink to="/phoenix">Phoenix</NavLink>
            <NavLink to="/cornville">Cornville</NavLink>
            <NavLink to="/elmirage">El Mirage</NavLink>
            <NavLink to="/myanmar">Myanmar</NavLink>
            <NavLink to="/philippines">Philippines</NavLink>
            <NavLink to="/">All Locations</NavLink>
          </DropDownContent>
        </HiddenDiv>
        <NavLink
          to="/serve"
          css={css`
            @media (max-width: 640px) {
              display: none;
            }
          `}
        >
          Serve
        </NavLink>
        <GiveLink
          href="https://crosschurchcares.churchcenter.com/registrations/events"
          css={css`
            @media (max-width: 640px) {
              display: none;
            }
          `}
        >
          Events
        </GiveLink>
        <GiveLink
          href="https://youtube.com/playlist?list=PLcw5WfAS2DN4cytsMSQuCZTFQ6ViPhcd3&si=1_Z39GLHTMmC_6t5"
          css={css`
            @media (max-width: 640px) {
              display: none;
            }
          `}
        >
          Podcast
        </GiveLink>
        <GiveLink
          href="https://blog.crosschurchcares.com"
          css={css`
            @media (max-width: 640px) {
              display: none;
            }
          `}
        >
          Blog
        </GiveLink>
        <GiveLink
          href="https://pushpay.com/pay/palmvistasurprise/3wVi2Zku2LnNwZiQ57OSwA"
          css={css`
            @media (max-width: 640px) {
              display: none;
            }
          `}
        >
          GIVE
        </GiveLink>
      </nav>
    </header>
  </>
)

export default Header
