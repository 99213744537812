import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const MainText = styled.h3`
  font-family: Montserrat;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.25rem;
  text-align: center;
  margin: 0 auto;
  line-height: 1.25rem;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
`

const LandingBar = () => (
  <div
    css={css`
      background: #312e2e;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem calc((100vw - 1050px) / 2);
      height: 135px;
      align-items: center;
      @media (max-width: 640px) {
        height: 90px;
        padding: 2rem;
      }
    `}
  >
    <MainText>
      Cross church cares about making Jesus Known
      <br
        css={css`
          @media (max-width: 640px) {
            display: none;
          }
        `}
      />{" "}
      by creating a movement of multiplying churches.
    </MainText>
  </div>
)

export default LandingBar
